<template>
  <div class="router-menu-title">
    <div class="router-menu-title-top f-c">
      <div class="f-l router-menu-title-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="router-menu-title-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l router-menu-title-top-title">
        <div v-if="isCustomize">
          <slot name="titleContent"></slot>
        </div>
        <div v-else>
          <span v-for="(item, index) in routerTitleList" :key="item.name"
            >{{ item.name }}
            {{ index !== routerTitleList.length - 1 ? " / " : "" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "routerMenuTitle",
  props: {
    routerTitleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isCustomize: {
      type: Boolean,
      default: false,
    },
    // 需要特定上个页面跳转的路由path
    lastRoute: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    goBack() {
      if (!this.lastRoute) {
        this.$router.go(-1);
      } else {
        this.$router.replace({ path: this.lastRoute });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.router-menu-title {
  background: #fff;

  border-radius: 4px;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
    &-text {
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &-top-title {
    font-size: 15px;
    span:last-child {
      color: #999;
    }
  }
}
</style>
