<template>
  <div class="student-reported">
    <router-menu-title :routerTitleList="routerTitleList"></router-menu-title>
    <p class="student-reported-details">
      <span
        >{{ $t("el.authDialog.name") }}{{ $t("el.symbol.colon")
        }}{{ obj.studentName }}</span
      >
      <span>
        {{ $t("el.courseClassManage.studentID") }} {{ $t("el.symbol.colon")
        }}{{ obj.studentCode }}</span
      >
      <!-- <span
        >{{ $t("el.planCourse.school") }}{{ $t("el.symbol.colon")
        }}{{ obj.schoolName }}</span
      > -->
      <span
        >{{ $t("el.schoolResourceManage.grade") }}{{ $t("el.symbol.colon")
        }}{{ obj.gradeName }}</span
      >
      <span
        >{{ $t("el.planCourse.class") }}{{ $t("el.symbol.colon")
        }}{{ obj.className }}</span
      >
    </p>
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :autoLoad="false"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :tableOptions="{
        height: tableHeight,
      }"
    />
  </div>
</template>

<script>
import routerMenuTitle from "@/components/biz-components/router-menu-title/index.vue";
export default {
  name: "classStudentReported",
  components: {
    routerMenuTitle,
  },
  data() {
    return {
      obj: {},
      tableHeight: undefined,
    };
  },
  created() {
    this.init();
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.schoolResourceManage.grade"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.schoolResourceManage.subjects"),
          prop: "subjectName",
        },
        {
          label: this.$t("el.courseClassManage.InClass"),
          prop: "className",
        },
        {
          label: this.$t("el.planCourse.instructor"),
          prop: "techerName",
        },
        {
          label: this.$t("el.courseClassManage.createTime"),
          prop: "createTime",
        },
      ];
    },
    routerTitleList() {
      return [
        {
          name: this.$t("el.infrastructure.studentManagement"),
        },
        {
          name: this.$t(this.$route.meta.title),
        },
      ];
    },
  },
  methods: {
    init() {
      if (this.$route.query.obj) {
        this.obj = JSON.parse(this.$route.query.obj);
      }
      this.$nextTick(() => {
        this.$refs["xmTable"].query();
      });
    },
    // 获取该学生的已报课程列表
    fatchMethod(params) {
      console.log("params", params);

      let data = {
        studentId: this.obj.id,
        params: {
          offset: params.offset,
          limit: params.limit,
        },
      };

      return this.$store
        .dispatch("getStudentReportedList", data)
        .then((result) => {
          return result;
        });
    },
  },
};
</script>

<style scoped lang="less">
.student-reported {
  background: #fff;
  padding: 15px;
  /deep/ .el-form--inline {
    display: none;
  }
  &-details {
    font-size: 15px;
    margin: 20px 0;
    span {
      padding-left: 15px;
    }
  }
}
</style>
